<template>
    <p v-if="messageFilter">
        <span v-if="user !== ''"><b>{{user}}</b>:</span> {{message.message}} <span class="timestamp">{{message.createdAt}}</span>
    </p>
</template>

<script>
    export default {
        name: "CameraChatMessage",
        props: {
            message: {
                id: Number,
                createdAt: String,
                userId: Number,
                message: String,
                messageType: String,
                updatedAt: String,
            }
        },
        computed: {
            user: function () {
                if (this.message.messageType === "MsgToUser") {
                    return "Elevator";
                }else if (this.message.messageType === "MsgToCamera") {
                    const userId = this.$config._userId;
                    if (this.message.userId === userId) {
                        return "You";
                    }
                    return "Admin";
                }else if (this.message.messageType === "System") {
                    return "";
                }
                return "";
            },
            messageFilter: function() {
                switch (this.message.messageType) {
                    case "MsgToUser":
                        return true;
                    case "MsgToCamera":
                        return true;
                    default:
                        return false;
                }
            }
        }
    }
</script>

<style scoped>
    .timestamp {
        float: right;
        opacity: 0.7;
        text-align: right;
    }
</style>