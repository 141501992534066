<template>

  <div id="camera-view">
    <h2 is="sui-header" class="dividing">{{ cameraTitle }}</h2>

    <!-- ============ Main Content BEGIN ============ -->
    <error-message :title="error" v-if="error!==''"></error-message>
    <div id="video-holder">
      <SipSession :session="session" :status="status"></SipSession>
      <div id="cover" :class="{hideCover: !ringring}">
        <sui-button icon="play" id="play-button" @click.prevent="answerPhone">Answer</sui-button>
      </div>
    </div>

    <br>
    <sui-form>
      <sui-form-fields>
        <sui-form-field>
          <sui-button @click.prevent="hangUp" color="red" icon="ban">
            Hang up
          </sui-button>
        </sui-form-field>
        <sui-form-field>
          <sui-button @click.prevent="dialerCall" color="green" icon="phone volume">Dail to the Elevator</sui-button>
        </sui-form-field>
      </sui-form-fields>

    </sui-form>
    <hr>
    <p>
      Status: {{ status }}
      <sui-icon name="check circle outline" v-if="status==='registered'" color="green"></sui-icon>
      <sui-icon name="spinner" v-else-if="status==='connecting'" color="red" loading></sui-icon>
      <sui-icon name="sync" v-else  color="blue" loading></sui-icon>
    </p>

    <sui-form>
      <sui-form-fields>
        <sui-form-field>
          <sui-button @click.prevent="connect" size="small">Reconnect</sui-button>
        </sui-form-field>
      </sui-form-fields>

    </sui-form>
    <p></p>
    <!-- ============ Main Content END ============ -->
  </div>
</template>

<script>
import axios from "axios";
import JsSIP from "jssip";
import SipSession from "@/views/dashboard/video/SipSession";
import RingRing from "@/assets/ringring.ogg";
import ErrorMessage from "@/components/ErrorMessage";

export default {
  name: "SIP",
  components: {ErrorMessage, SipSession},
  data: function () {
    return {
      dialTo: "",

      // 'connecting' / disconnected' / 'connected' / 'registered'
      status: 'disconnected',
      uri: '',
      cameraTitle: "",
      error: "",
      settings: {
        display_name: "caller",
        uri: "",
        password: "",
        socket: {
          uri: "wss://tsaro-alpha.sip.signalwire.com",
          via_transport: "tls"
        },
        registrar_server: "tsaro-alpha.sip.signalwire.com",
        contact_uri: null,
        authorization_user: null,
        instance_id: null,
        session_timers: true,
        use_preloaded_route: false,
        pcConfig: {
          rtcpMuxPolicy: "negotiate",
          iceServers: [
            {
              urls: ["stun:stun.l.google.com:19302"]
            }
          ]
        },
        callstats: {
          enabled: false,
          AppID: null,
          AppSecret: null
        }
      },

      session: null,
      incomingSession: null,

      mounted: false,
      ua: null,
      u: null,

      ringring: false,
    }
  },
  destroyed() {
    console.log("vue destroyed")
    this.ua.stop();
    this.mounted = false;
  },
  created: async function () {
    console.log("created");
    this.loadViewerInformation();
  },
  methods: {
    dialBack: function () {

    },
    loadViewerInformation: function () {
      axios({
        method: "POST",
        url: "/api/camera/viewer",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: {
          token: this.$cookies.get('token'),
          tokenId: this.$cookies.get('tokenId'),
          cameraId: this.$route.params.cameraId
        }
      }).then(async (response) => {
        console.log(response);
        if (response.data) {
          this.cameraTitle = `${response.data.name} at ${response.data.locationTitle} `;
          this.settings.password = response.data.sipPassword;
          this.settings.uri = response.data.sipReference;
          this.dialTo = response.data.intercomNumber;
          this.error = "";
          await this.connect();
        }
      }).catch((err) => {
        console.log(err);
        //this.$router.push('/login');
        this.error = "something went wrong, please let me know if "
      }).finally(() => {
        this.loading = false;
      });
    },
    beforeUnload: function () {
      console.log("before unload");
      // this.sendResponseViewReset();
    },
    playRingTone: async function () {
      let p = new Audio(RingRing)
      this.ringring = true;
      while (this.ringring === true) {
        await p.play();
      }
    },
    connect: async function () {
      this.mounted = true;
      const logger = console;

      const settings = this.settings;
      const socket = new JsSIP.WebSocketInterface(settings.socket.uri);

      if (settings.socket['via_transport'] !== 'auto')
        socket['via_transport'] = settings.socket['via_transport'];

      console.log(this.settings);

      try {
        this.ua = new JsSIP.UA(
            {
              uri: settings.uri,
              password: settings.password,
              'display_name': settings.display_name,
              sockets: [socket],
              'registrar_server': settings.registrar_server,
              'contact_uri': settings.contact_uri,
              'authorization_user': settings.authorization_user,
              'instance_id': settings.instance_id,
              'session_timers': settings.session_timers,
              'use_preloaded_route': settings.use_preloaded_route
            });

        // TODO: For testing.
        window.UA = this.ua;
        console.log("successfully created instance")
      } catch (error) {
        console.log({
          level: 'error',
          title: 'Wrong JsSIP.UA settings',
          message: error.message
        });

      }


      this.ua.on('connecting', () => {
        if (!this.mounted)
          return;

        logger.log('UA "connecting" event');

        this.uri = this.ua.configuration.uri.toString();
        this.status = 'connecting';

      });


      this.ua.on('connected', () => {
        if (!this.mounted)
          return;

        logger.log('UA "connected" event');

        this.status = 'connected';
      });

      this.ua.on('disconnected', () => {
        if (!this.mounted)
          return;

        logger.log('UA "disconnected" event');

        this.status = 'disconnected';
      });

      this.ua.on('registered', () => {
        if (!this.mounted)
          return;

        logger.log('UA "registered" event');

        this.status = 'registered';
      });

      this.ua.on('unregistered', () => {
        if (!this.mounted)
          return;

        logger.log('UA "unregistered" event');

        if (this.ua.isConnected()) {
          this.status = 'connected';
        } else {
          this.status = 'disconnected';
        }
      });

      this.ua.on('registrationFailed', (data) => {
        if (!this.mounted)
          return;

        logger.log('UA "registrationFailed" event');

        if (this.ua.isConnected()) {
          this.status = 'connected';
        } else {
          this.status = 'disconnected';
        }

        logger.log({
          level: 'error',
          title: 'Registration failed',
          message: data.cause
        });
      });

      this.ua.on('newMessage', (data) => {
        logger.log('UA "newMessage" event');
        console.log(data);
      });

      // New RTC Connection
      this.ua.on('newRTCSession', async (data) => {
        if (!this.mounted)
          return;

        // TODO: For testing.
        window.SESSION = data.session;

        if (data.originator === 'local')
          return;

        logger.log('UA "newRTCSession" event');


        const session = data.session;

        // Avoid if busy or other incoming
        if (this.session || this.incomingSession) {
          logger.log('incoming call replied with 486 "Busy Here"');

          session.terminate(
              {
                'status_code': 486,
                'reason_phrase': 'Busy Here'
              });

          return;
        }

        //audioPlayer.play('ringing');
        //this.setState({ incomingSession: session });
        this.incomingSession = session;

        session.on('failed', () => {
          console.log("session failed");

          this.ringring = false;
          this.incomingSession = null;
          this.session = null;
        });

        session.on('ended', () => {
          console.log("session ended");

          this.ringring = false;
          this.incomingSession = null;
          this.session = null;
        });

        session.on('accepted', () => {
          console.log("session accepted");

          this.ringring = false;
          this.session = session;
          this.incomingSession = null;
        });


        await this.playRingTone();
      });

      //Star UA
      this.ua.start();

    },
    answerPhone: async function () {
      console.log("Answer Phone")
      console.log(this.incomingSession);
      const session = this.incomingSession;
      if (session === null) {
        console.log("no active session")
        return
      }
      session.answer({
        pcConfig: this.settings.pcConfig
      });

    },
    dialerCall: async function () {
      console.log("dialerCall");
      if (this.dialTo === "" ){
        return;
      }

      console.log('handleOutgoingCall() [uri:"%s"]', this.dialTo);

      const session = this.ua.call(this.dialTo,
          {
            pcConfig: this.settings.pcConfig || {iceServers: []},
            mediaConstraints:
                {
                  audio: true,
                  video: true
                },
            rtcOfferConstraints:
                {
                  offerToReceiveAudio: 1,
                  offerToReceiveVideo: 1
                }
          });

      session.on('connecting', () => {
        this.session = session
        console.log("dailer: connecting");
      });

      session.on('progress', () => {
        console.log("dailer: progress");
      });

      session.on('failed', (data) => {
        this.session = null;

        console.log(
            {
              level: 'error',
              title: 'Call failed',
              message: data.cause
            });
      });

      session.on('ended', () => {
        this.session = null;
        console.log("dailer: ended");
      });

      session.on('accepted', () => {
        console.log("dailer: accepted")
      });

    },
    hangUp: async function () {
      console.log('hangUp');

      this.ringring = false;
      this.mounted = false;

      console.log("hangup: session:" + this.session);
      if (this.session !== null){
        this.session.terminate();
      }
      this.session = null;

      console.log("hangup: ua:" + this.ua)
      if (this.ua !== null){
        this.ua.terminateSessions();
      }

      window.location.reload();
    }
  }
}
</script>

<style scoped>
.full-width {
  width: 100%;
}


#main-viewer, #sip-viewer {
  width: 100%;
  background-color: black;
  border-radius: 5px;
}

#local-viewer {
  width: 20%;
  background-color: grey;
  position: absolute;
  right: 15px;
  bottom: 160px;
  max-height: 90px;
  border-radius: 5px;
  margin-bottom: -90px;
}

#video-holder {
  position: relative;
}

#cover {
  background: rgba(128, 128, 128, 0.28);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  width: 200px;
  height: 50px;
  margin-top: -30px;
}

#video-holder {
  position: relative;
}

.hideCover {
  display: none;
}
</style>
