<template>

    <div id="camera-view">
        <h2 is="sui-header" class="dividing">{{cameraTitle}}</h2>
        <div id="video-holder">
            <video id="main-viewer" controls autoplay></video>
            <div id="cover" :class="{hideCover: hideCover}">
                <sui-button icon="play" id="play-button" @click.prevent="playVideo">Play</sui-button>
            </div>
            <video id="local-viewer" autoplay playsinline muted="muted" :class="{hideCover: hideSmallVideoViewer}"></video>
        </div>

        <sui-divider hidden></sui-divider>
        <sui-form>
            <sui-form-field style="text-align: right">
                <sui-form-fields>
                    <sui-form-field :width="6" style="padding: 5px;">
                        <sui-checkbox toggle label="Stream to the elevator" style="float: left" @change="toggleStreamer"/>
                    </sui-form-field>
                    <sui-form-field :width="10" style="text-align: right">
                        <sui-button
                            color="grey" basic size="tiny"
                                icon="sync" @click.prevent="sendResponseStreamerReset">
                            Reset Video Stream
                        </sui-button>
                        <sui-button
                                color="red" basic size="tiny"
                                icon="close icon" @click.prevent="sendResponseViewReset">
                            Close Session
                        </sui-button>
                    </sui-form-field>
                </sui-form-fields>
            </sui-form-field>
        </sui-form>
        <sui-divider horizontal fitted></sui-divider>
        <sui-form>
            <sui-form-field>
                <sui-form-fields>
                    <sui-form-field :width="11">
                        <sui-input placeholder="Message" id="input-message" v-model="messageInput" @submit="submitButtonClick"></sui-input>
                    </sui-form-field>
                    <sui-form-field :width="5">
                        <sui-button-group class="full-width">
                            <sui-dropdown button text="Quick Response" class="basic">
                                <sui-dropdown-menu>
                                    <sui-dropdown-item v-for="q in quickResponseMessages" :value="q.value"
                                                       :key="q.value" @click.prevent="sendResponseMessage(q.message)">
                                        {{q.message}}
                                    </sui-dropdown-item>
                                </sui-dropdown-menu>
                            </sui-dropdown>
                            <sui-button id="message-send" color="blue" @click.prevent="submitButtonClick">Submit
                            </sui-button>
                        </sui-button-group>
                    </sui-form-field>
                </sui-form-fields>
            </sui-form-field>
        </sui-form>
        <div class="ui segments">
            <div class="ui segment">
                <camera-chat-message v-for="m in message" :key="m.id" :message="m"></camera-chat-message>
                <sui-button size="tiny" class="full-width" :disabled="!messageLoadMore" @click.prevent="loadMore">Load
                    More
                </sui-button>
            </div>
        </div>

    </div>
</template>


<script>
    import axios from "axios";
    import {SignalingClient, Role} from 'amazon-kinesis-video-streams-webrtc';
    import {KinesisVideo, KinesisVideoSignalingChannels} from 'aws-sdk';
    import CameraChatMessage from "@/views/dashboard/camera/CameraChatMessage";


    // eslint-disable-next-line no-unused-vars
    import adapter from 'webrtc-adapter';

    export default {
        name: "WebRTC",
        components: {CameraChatMessage},
        computed: {
            streamId: function () {
                return this.$route.params.cameraId
            }
        },
        data: function () {
            const quickResponseMessages = [
                "Are you okay?",
                "Can you hear me?",
                "Are you injured?",
            ];

            return {
                messageLoadMore: true,

                cameraTitle: "",
                ua: null,

                // Chat
                message: [],
                messageSplit: new Date().getTime(),
                messageLatest: 0,
                messageLimit: 20,
                messageOffset: 0,
                messageTimer: 0,

                quickResponseMessages: quickResponseMessages.map((i) => {
                    return {
                        value: i,
                        message: i,
                    }
                }),
                messageInput: "",

                // Video
                hideCover: false,
                hideSmallVideoViewer: true,
                viewer: {
                    localView: null,
                    remoteView: null,
                },
                streamerConfig: {
                    region: "",
                    channelName: "",
                    clientId: "",
                    sendVideo: false,
                    sendAudio: false,
                    openDataChannel: true,
                    widescreen: true,
                    fullscreen: false,
                    useTrickleICE: true,
                    natTraversalDisabled: false,
                    forceTURN: false,
                    accessKeyId: "",
                    endpoint: null,
                    secretAccessKey: "",
                    sessionToken: null,
                },
                streamerMasterConfig: {
                    clientId: "",
                    sendVideo: false,
                    sendAudio: false,
                    openDataChannel: true,
                    widescreen: true,
                    fullscreen: false,
                    useTrickleICE: true,
                    natTraversalDisabled: false,
                    forceTURN: false,
                    endpoint: null,
                    sessionToken: null,

                    onClose: () => {
                    },
                    onError: () => {
                    },

                    signalingClient: null,
                    peerConnectionByClientId: {},
                    dataChannelByClientId: {},
                    localStream: null,
                    remoteStreams: [],
                    peerConnectionStatsInterval: null,
                }
            }
        },
        created: function () {
            this.loading = true;
            this.loadViewerInformation();
            this.loadHistoryMessage();
            this.latestMessageTimestamp = new Date().getTime();
            if (this.messageTimer !== 0) {
                clearTimeout(this.messageTimer);
            }
            this.messageTimer = setTimeout(this.loadMessage, 0);
            window.addEventListener('beforeunload', this.beforeUnload)


            console.log("created");
        },

        methods: {
            loadViewerInformation: function () {
                axios({
                    method: "POST",
                    url: "/api/camera/viewer",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),
                        cameraId: this.$route.params.cameraId
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data.id) {

                        this.cameraTitle = `${response.data.name} at ${response.data.locationTitle} `;

                        this.streamerConfig.channelName = response.data.awsChannel;
                        this.streamerConfig.region = response.data.awsRegion;
                        this.streamerConfig.accessKeyId = response.data.awsKey;
                        this.streamerConfig.secretAccessKey = response.data.awsSecret;
                        this.streamerConfig.clientId = this.$cookies.get('tokenId');
                        //this.startViewer(()=>{}, ()=>{});
                    }
                }).catch((err) => {
                    console.log(err);
                    this.$router.push('/login');
                }).finally(() => {
                    this.loading = false;
                });
            },
            makeACall: function (){
              this.ua.start();

              // Register callbacks to desired call events
              let eventHandlers = {
                'progress': function() {
                  console.log('call is in progress');
                },
                'failed': function(e) {
                  console.log('call failed with cause: '+ e.data.cause);
                },
                'ended': function(e) {
                  console.log('call ended with cause: '+ e.data.cause);
                },
                'confirmed': function() {
                  console.log('call confirmed');
                }
              };

              let options = {
                'eventHandlers'    : eventHandlers,
                'mediaConstraints' : { 'audio': true, 'video': true }
              };

              let session = this.ua.call('sip:bob@example.com', options);
              console.log(session)

            },
            beforeUnload: function(){
                console.log("before unload");
                // this.sendResponseViewReset();
            },
            loadMore: async function () {
                await this.loadHistoryMessage();
            },
            loadHistoryMessage: async function () {
                this.messageLatest = this.messageSplit;
                try {
                    if (!this.$route.params.cameraId) {
                        return;
                    }
                    let response = await axios({
                        method: "POST",
                        url: "/api/message/userGetMessage",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        data: {
                            token: this.$cookies.get('token'),
                            tokenId: this.$cookies.get('tokenId'),
                            cameraId: this.$route.params.cameraId,

                            holdOnline: false,

                            split: this.messageSplit,
                            limit: this.messageLimit,
                            offset: this.messageOffset,
                        }
                    });
                    if (response.data.status === "ok") {
                        console.log(response.data.result.rows);
                        this.message.push(...response.data.result.rows);
                        if (response.data.result.rows.length < this.messageLimit) {
                            this.messageLoadMore = false
                        }
                        this.messageOffset += response.data.result.rows.length;
                    }

                } catch (e) {
                    console.log(e);
                }
            },
            loadMessage: async function () {
                console.log("Waiting for message - cameraId:" + this.$route.params.cameraId);
                try {
                    if (!this.$route.params.cameraId) {
                        clearTimeout(this.messageTimer);
                        return;
                    }
                    let response = await axios({
                        method: "POST",
                        url: "/api/message/userGetMessage",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        data: {
                            token: this.$cookies.get('token'),
                            tokenId: this.$cookies.get('tokenId'),
                            cameraId: this.$route.params.cameraId,

                            holdOnline: true,
                            split:  this.messageLatest,
                        }
                    });

                    this.messageLatest = response.data.latest;
                    console.log(this.messageLatest);
                    if (response.data.status === "ok"){
                        for (let m of response.data.messages) {
                            // handle each message in the list
                            console.log(`received message: ${m.message} -  ${m.messageType}`);
                            this.message.unshift(m);
                        }

                        this.messageTimer = setTimeout(this.loadMessage, 0);
                        return;
                    }


                    this.messageTimer = setTimeout(this.loadMessage, 1000);
                } catch (e) {
                    console.log(e);
                    this.messageTimer = setTimeout(this.loadMessage, 1000)
                }

            },
            sendResponse: function (message, messageType) {
                axios({
                    method: "POST",
                    url: "/api/message/userSendMessage",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        cameraId: this.$route.params.cameraId,

                        message: message,
                        messageType: messageType,
                    }
                }).then((response) => {
                    console.log(response);
                    if (response.data.status === "ok") {
                        this.message.unshift(response.data.message);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.$router.push('/login');
                }).finally(() => {
                    this.loading = false;
                });
            },
            sendResponseMessage: function (message) {
                console.log("send response: " + message);
                this.sendResponse(message, "MsgToCamera")
            },
            sendResponseViewReset: function () {
                console.log("reset camera");
                this.sendResponse("ResetCamera", "System")
            },
            sendResponseStreamerReset: function () {
                console.log("reset streamer");
                this.sendResponse("ResetStreamer", "System")
                alert("The streamer is restarting.");
                setTimeout(()=>{
                    console.log("reload page");
                    location.reload();
                }, 1000);
            },
            submitButtonClick: function () {
                if (this.messageInput !== "") {
                    this.sendResponseMessage(this.messageInput);
                    this.messageInput = "";
                }
            },
            playVideo: function () {
                if (this.streamerConfig.channelName !== "") {
                    this.startViewer(this.streamerConfig.channelName, () => {
                    }, () => {
                    });
                    this.hideCover = true;
                }
            },
            createSignalingChannel: async function (channelName) {
                // Create KVS client
                const kinesisVideoClient = new KinesisVideo({
                    region: this.streamerConfig.region,
                    accessKeyId: this.streamerConfig.accessKeyId,
                    secretAccessKey: this.streamerConfig.secretAccessKey,

                    sessionToken: this.streamerMasterConfig.sessionToken,
                    endpoint: this.streamerMasterConfig.endpoint,

                });

                // Get signaling channel ARN
                try {
                    await kinesisVideoClient
                        .createSignalingChannel({
                            ChannelName: channelName,
                        })
                        .promise();
                } catch (e) {
                    if (e.code !== "ResourceInUseException") {
                        return null
                    }
                }

                // Get signaling channel ARN
                const describeSignalingChannelResponse = await kinesisVideoClient
                    .describeSignalingChannel({
                        ChannelName: channelName,
                    })
                    .promise();
                //console.dir(describeSignalingChannelResponse);

                return describeSignalingChannelResponse.ChannelInfo;

            },
            startStreamer: async function (channelName, onStatsReport, onOpen) {
                console.log("start streamer");

                this.viewer.localView = document.getElementById("local-viewer");

                // Create KVS client
                const kinesisVideoClient = new KinesisVideo({
                    region: this.streamerConfig.region,
                    accessKeyId: this.streamerConfig.accessKeyId,
                    secretAccessKey: this.streamerConfig.secretAccessKey,

                    sessionToken: this.streamerMasterConfig.sessionToken,
                    endpoint: this.streamerMasterConfig.endpoint,

                    correctClockSkew: true,
                });

                // Get signaling channel ARN
                const describeSignalingChannelResponse = await kinesisVideoClient
                    .describeSignalingChannel({
                        ChannelName: channelName,
                    })
                    .promise();
                const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
                //console.log('[MASTER] Channel ARN: ', channelARN);

                // Get signaling channel endpoints
                const getSignalingChannelEndpointResponse = await kinesisVideoClient
                    .getSignalingChannelEndpoint({
                        ChannelARN: channelARN,
                        SingleMasterChannelEndpointConfiguration: {
                            Protocols: ['WSS', 'HTTPS'],
                            Role: Role.MASTER,
                        },
                    })
                    .promise();
                const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
                    endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
                    return endpoints;
                }, {});
                //console.log('[MASTER] Endpoints: ', endpointsByProtocol);

                // Create Signaling Client
                this.streamerMasterConfig.signalingClient = new SignalingClient({
                    channelARN,
                    channelEndpoint: endpointsByProtocol.WSS,
                    role: Role.MASTER,
                    region: this.streamerConfig.region,
                    credentials: {
                        accessKeyId: this.streamerConfig.accessKeyId,
                        secretAccessKey: this.streamerConfig.secretAccessKey,
                        sessionToken: this.streamerConfig.sessionToken,
                    },
                    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
                });

                // Get ICE server configuration
                const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
                    region: this.streamerConfig.region,
                    accessKeyId: this.streamerConfig.accessKeyId,
                    secretAccessKey: this.streamerConfig.secretAccessKey,
                    sessionToken: this.streamerConfig.sessionToken,
                    endpoint: endpointsByProtocol.HTTPS,
                    correctClockSkew: true,
                });
                const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
                    .getIceServerConfig({
                        ChannelARN: channelARN,
                    })
                    .promise();
                console.log(getIceServerConfigResponse);
                const iceServers = [];
                iceServers.push({urls: `stun:stun.kinesisvideo.${this.streamerConfig.region}.amazonaws.com:443`});

                console.log('[MASTER] ICE servers: ', iceServers);

                const configuration = {
                    iceServers,
                    iceTransportPolicy: 'all',
                };

                const resolution = {width: {ideal: 1280}, height: {ideal: 720}};
                const constraints = {
                    video: resolution || false,
                    audio: true,
                };

                // Get a stream from the webcam and display it in the local view
                try {
                    this.streamerMasterConfig.localStream = await navigator.mediaDevices.getUserMedia(constraints);
                    this.viewer.localView.srcObject = this.streamerMasterConfig.localStream;
                } catch (e) {
                    console.dir(e);
                    console.error('[MASTER] Could not find webcam');
                    return null;
                }

                this.streamerMasterConfig.signalingClient.on('open', onOpen);

                this.streamerMasterConfig.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
                    console.log('[MASTER] Received SDP offer from client: ' + remoteClientId);

                    // Create a new peer connection using the offer from the given client
                    const peerConnection = new RTCPeerConnection(configuration);
                    this.streamerMasterConfig.peerConnectionByClientId[remoteClientId] = peerConnection;

                    // Poll for connection stats
                    if (!this.streamerMasterConfig.peerConnectionStatsInterval) {
                        this.streamerMasterConfig.peerConnectionStatsInterval = setInterval(() => peerConnection.getStats().then(onStatsReport), 1000);
                    }

                    // Send any ICE candidates to the other peer
                    peerConnection.addEventListener('icecandidate', ({candidate}) => {
                        if (candidate) {
                            console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId);

                            // When trickle ICE is enabled, send the ICE candidates as they are generated.
                            console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
                            this.streamerMasterConfig.signalingClient.sendIceCandidate(candidate, remoteClientId);

                        } else {
                            console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);
                        }
                    });

                    // As remote tracks are received, add them to the remote view
                    peerConnection.addEventListener('track', event => {
                        console.log('[MASTER] Received remote track from client: ' + remoteClientId + " " + event);
                        /*
                        if (remoteView.srcObject) {
                            return;
                        }
                        remoteView.srcObject = event.streams[0];
                        */
                    });

                    this.streamerMasterConfig.localStream.getTracks().forEach(track => peerConnection.addTrack(track, this.streamerMasterConfig.localStream));
                    await peerConnection.setRemoteDescription(offer);

                    // Create an SDP answer to send back to the client
                    console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);
                    await peerConnection.setLocalDescription(
                        await peerConnection.createAnswer({
                            offerToReceiveAudio: true,
                            offerToReceiveVideo: true,
                        }),
                    );

                    // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
                    console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
                    this.streamerMasterConfig.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
                    console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
                });

                this.streamerMasterConfig.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
                    console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId);

                    // Add the ICE candidate received from the client to the peer connection
                    const peerConnection = this.streamerMasterConfig.peerConnectionByClientId[remoteClientId];
                    peerConnection.addIceCandidate(candidate);
                });

                this.streamerMasterConfig.signalingClient.on('close', this.streamerMasterConfig.onClose);

                this.streamerMasterConfig.signalingClient.on('error', this.streamerMasterConfig.onError);

                console.log('[MASTER] Starting this.streamerMasterConfig connection');
                this.streamerMasterConfig.signalingClient.open();
            },
            stopStreamer: async function () {
                console.log('[MASTER] Stopping master connection');
                if (this.streamerMasterConfig.signalingClient) {
                    this.streamerMasterConfig.signalingClient.close();
                    this.streamerMasterConfig.signalingClient = null;
                }

                Object.keys(this.streamerMasterConfig.peerConnectionByClientId).forEach(clientId => {
                    this.streamerMasterConfig.peerConnectionByClientId[clientId].close();
                });
                this.streamerMasterConfig.peerConnectionByClientId = [];

                if (this.streamerMasterConfig.localStream) {
                    this.streamerMasterConfig.localStream.getTracks().forEach(track => track.stop());
                    this.streamerMasterConfig.localStream = null;
                }

                this.streamerMasterConfig.remoteStreams.forEach(remoteStream => remoteStream.getTracks().forEach(track => track.stop()));
                this.streamerMasterConfig.remoteStreams = [];

                if (this.streamerMasterConfig.peerConnectionStatsInterval) {
                    clearInterval(this.streamerMasterConfig.peerConnectionStatsInterval);
                    this.streamerMasterConfig.peerConnectionStatsInterval = null;
                }

                if (this.streamerMasterConfig.localView) {
                    this.streamerMasterConfig.localView.srcObject = null;
                }

                /*
                if (this.streamerMasterConfig.remoteView) {
                    this.streamerMasterConfig.remoteView.srcObject = null;
                }
                 */

                if (this.streamerMasterConfig.dataChannelByClientId) {
                    this.streamerMasterConfig.dataChannelByClientId = {};
                }


            },
            startViewer: async function (channelName, onStatsReport, onRemoteDataMessage) {
                this.viewer.localView = document.getElementById("local-viewer");
                this.viewer.remoteView = document.getElementById("main-viewer");

                // Create KVS client
                const kinesisVideoClient = new KinesisVideo({
                    region: this.streamerConfig.region,
                    accessKeyId: this.streamerConfig.accessKeyId,
                    secretAccessKey: this.streamerConfig.secretAccessKey,
                    sessionToken: this.streamerConfig.sessionToken,
                    endpoint: this.streamerConfig.endpoint,
                    correctClockSkew: true,
                });

                // Get signaling channel ARN
                const describeSignalingChannelResponse = await kinesisVideoClient
                    .describeSignalingChannel({
                        ChannelName: channelName,
                    })
                    .promise();
                const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
                console.log('[VIEWER] Channel ARN: ', channelARN);

                // Get signaling channel endpoints
                const getSignalingChannelEndpointResponse = await kinesisVideoClient
                    .getSignalingChannelEndpoint({
                        ChannelARN: channelARN,
                        SingleMasterChannelEndpointConfiguration: {
                            Protocols: ['WSS', 'HTTPS'],
                            Role: Role.VIEWER,
                        },
                    })
                    .promise();
                const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
                    endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
                    return endpoints;
                }, {});
                console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

                const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
                    region: this.streamerConfig.region,
                    accessKeyId: this.streamerConfig.accessKeyId,
                    secretAccessKey: this.streamerConfig.secretAccessKey,
                    sessionToken: this.streamerConfig.sessionToken,
                    endpoint: endpointsByProtocol.HTTPS,
                    correctClockSkew: true,
                });

                // Get ICE server configuration
                const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
                    .getIceServerConfig({
                        ChannelARN: channelARN,
                    })
                    .promise();
                const iceServers = [];
                if (!this.streamerConfig.natTraversalDisabled && !this.streamerConfig.forceTURN) {
                    iceServers.push({urls: `stun:stun.kinesisvideo.${this.streamerConfig.region}.amazonaws.com:443`});
                }
                if (!this.streamerConfig.natTraversalDisabled) {
                    getIceServerConfigResponse.IceServerList.forEach(iceServer =>
                        iceServers.push({
                            urls: iceServer.Uris,
                            username: iceServer.Username,
                            credential: iceServer.Password,
                        }),
                    );
                }
                console.log('[VIEWER] ICE servers: ', iceServers);

                // Create Signaling Client
                this.viewer.signalingClient = new SignalingClient({
                    channelARN,
                    channelEndpoint: endpointsByProtocol.WSS,
                    clientId: this.streamerConfig.clientId,
                    role: Role.VIEWER,
                    region: this.streamerConfig.region,
                    credentials: {
                        accessKeyId: this.streamerConfig.accessKeyId,
                        secretAccessKey: this.streamerConfig.secretAccessKey,
                        sessionToken: this.streamerConfig.sessionToken,
                    },
                    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
                });

                //const resolution = this.streamerConfig.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };
                const resolution = this.streamerConfig.widescreen ? {
                    width: {ideal: 540},
                    height: {ideal: 360}
                } : {width: {ideal: 640}, height: {ideal: 480}};
                const constraints = {
                    video: this.streamerConfig.sendVideo ? resolution : false,
                    audio: this.streamerConfig.sendAudio,
                };
                console.log(constraints);
                const configuration = {
                    iceServers,
                    iceTransportPolicy: this.streamerConfig.forceTURN ? 'relay' : 'all',
                };
                this.viewer.peerConnection = new RTCPeerConnection(configuration);
                if (this.streamerConfig.openDataChannel) {
                    this.viewer.dataChannel = this.viewer.peerConnection.createDataChannel('kvsDataChannel');
                    this.viewer.peerConnection.ondatachannel = event => {
                        event.channel.onmessage = onRemoteDataMessage;
                    };
                }

                // Poll for connection stats
                this.viewer.peerConnectionStatsInterval = setInterval(() => {
                    if (this.viewer.peerConnection) {
                        this.viewer.peerConnection.getStats().then(onStatsReport)
                    } else {
                        clearInterval(this.viewer.peerConnectionStatsInterval);
                    }

                }, 1000);

                this.viewer.signalingClient.on('open', async () => {
                    console.log('[VIEWER] Connected to signaling service');

                    // Get a stream from the webcam, add it to the peer connection, and display it in the local view
                    /*
                    if (this.streamerConfig.sendVideo) {
                        try {
                            this.viewer.localStream = await navigator.mediaDevices.getUserMedia(constraints);
                            this.viewer.localStream.getTracks().forEach(track => this.viewer.peerConnection.addTrack(track, this.viewer.localStream));
                            this.viewer.localView.srcObject = this.viewer.localStream;
                        } catch (e) {
                            console.error('[VIEWER] Could not find webcam');
                            return;
                        }
                    }
                    */

                    // Create an SDP offer to send to the master
                    console.log('[VIEWER] Creating SDP offer');
                    await this.viewer.peerConnection.setLocalDescription(
                        await this.viewer.peerConnection.createOffer({
                            offerToReceiveAudio: true,
                            offerToReceiveVideo: true,
                        }),
                    );

                    // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
                    if (this.streamerConfig.useTrickleICE) {
                        console.log('[VIEWER] Sending SDP offer');
                        this.viewer.signalingClient.sendSdpOffer(this.viewer.peerConnection.localDescription);
                    }
                    console.log('[VIEWER] Generating ICE candidates');
                });

                this.viewer.signalingClient.on('sdpAnswer', async answer => {
                    // Add the SDP answer to the peer connection
                    console.log('[VIEWER] Received SDP answer');
                    await this.viewer.peerConnection.setRemoteDescription(answer);
                });

                this.viewer.signalingClient.on('iceCandidate', candidate => {
                    // Add the ICE candidate received from the MASTER to the peer connection
                    console.log('[VIEWER] Received ICE candidate');
                    this.viewer.peerConnection.addIceCandidate(candidate);
                });

                this.viewer.signalingClient.on('close', () => {
                    console.log('[VIEWER] Disconnected from signaling channel');
                });

                this.viewer.signalingClient.on('error', error => {
                    console.error('[VIEWER] Signaling client error: ', error);
                });

                // Send any ICE candidates to the other peer
                this.viewer.peerConnection.addEventListener('icecandidate', ({candidate}) => {
                    if (candidate) {
                        console.log('[VIEWER] Generated ICE candidate');

                        // When trickle ICE is enabled, send the ICE candidates as they are generated.
                        if (this.streamerConfig.useTrickleICE) {
                            console.log('[VIEWER] Sending ICE candidate');
                            this.viewer.signalingClient.sendIceCandidate(candidate);
                        }
                    } else {
                        console.log('[VIEWER] All ICE candidates have been generated');

                        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
                        if (!this.streamerConfig.useTrickleICE) {
                            console.log('[VIEWER] Sending SDP offer');
                            this.viewer.signalingClient.sendSdpOffer(this.viewer.peerConnection.localDescription);
                        }
                    }
                });

                // As remote tracks are received, add them to the remote view
                this.viewer.peerConnection.addEventListener('track', event => {
                    console.log('[VIEWER] Received remote track');
                    if (this.viewer.remoteView.srcObject) {
                        return;
                    }
                    this.viewer.remoteStream = event.streams[0];
                    this.viewer.remoteView.srcObject = this.viewer.remoteStream;


                });

                console.log('[VIEWER] Starting viewer connection');
                this.viewer.signalingClient.open();
            },
            stopViewer: () => {
                console.log('[VIEWER] Stopping viewer connection');
                if (this.viewer.signalingClient) {
                    this.viewer.signalingClient.close();
                    this.viewer.signalingClient = null;
                }

                if (this.viewer.peerConnection) {
                    this.viewer.peerConnection.close();
                    this.viewer.peerConnection = null;
                }

                /*
                if (this.viewer.localStream) {
                    this.viewer.localStream.getTracks().forEach(track => track.stop());
                    this.viewer.localStream = null;
                }
                */

                if (this.viewer.remoteStream) {
                    this.viewer.remoteStream.getTracks().forEach(track => track.stop());
                    this.viewer.remoteStream = null;
                }

                if (this.viewer.peerConnectionStatsInterval) {
                    clearInterval(this.viewer.peerConnectionStatsInterval);
                    this.viewer.peerConnectionStatsInterval = null;
                }

                /*
                if (this.viewer.localView) {
                    this.viewer.localView.srcObject = null;
                }
                */

                if (this.viewer.remoteView) {
                    this.viewer.remoteView.srcObject = null;
                }

                if (this.viewer.dataChannel) {
                    this.viewer.dataChannel = null;
                }
            },
            sendViewerMessage: (message) => {
                if (this.viewer.dataChannel) {
                    try {
                        this.viewer.dataChannel.send(message);
                    } catch (e) {
                        console.error('[VIEWER] Send DataChannel: ', e.toString());
                    }
                }
            },

            toggleStreamer: async function (value) {
                console.log(value);
                let channelName = "user-" + this.$config._userId;
                if (value) {
                    this.hideSmallVideoViewer = false;
                    let channel = await this.createSignalingChannel(channelName);
                    console.log(channel);
                    this.sendResponse(channelName, "UserStartStr");
                    await this.startStreamer(channelName, () => {
                    }, () => {
                    });

                } else {
                    this.hideSmallVideoViewer = true;
                    await this.sendResponse(channelName, "UserStopStr")
                    await this.stopStreamer();
                }

            },
        }

    }
</script>

<style scoped>
    .full-width {
        width: 100%;
    }


    #main-viewer, #sip-viewer {
        width: 100%;
        background-color: black;
        border-radius: 5px;
    }

    #local-viewer {
        width: 20%;
        background-color: grey;
        position: absolute;
        right: 15px;
        bottom: 160px;
        max-height: 90px;
        border-radius: 5px;
        margin-bottom: -90px;
    }

    #video-holder {
        position: relative;
    }

    #cover {
        background: rgba(128, 128, 128, 0.28);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    #play-button {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -100px;
        width: 200px;
        height: 50px;
        margin-top: -30px;
    }

    .hideCover {
        display: none;
    }
</style>
