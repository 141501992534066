<template>
  <div v-if="viewReady">
    <WebRTC v-if="useWebRTC"></WebRTC>
    <SIP v-else></SIP>
  </div>
</template>

<script>
import axios from "axios";
import WebRTC from "@/views/dashboard/camera/framework/WebRTC";
import SIP from "@/views/dashboard/camera/framework/SIP";

export default {
  name: "CameraView",
  components: {SIP, WebRTC},
  computed: {
    streamId: function () {
      return this.$route.params.cameraId
    }
  },
  data: function () {
    return {
      loading: true,
      useWebRTC: false,
      viewReady: false,
    }
  },
  created: function () {
    this.loading = true;
    this.loadViewerInformation();
    window.addEventListener('beforeunload', this.beforeUnload)
  },

  methods: {
    loadViewerInformation: function () {
      axios({
        method: "POST",
        url: "/api/camera/type",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: {
          token: this.$cookies.get('token'),
          tokenId: this.$cookies.get('tokenId'),
          cameraId: this.$route.params.cameraId
        }
      }).then((response) => {
        console.log(response.data);
        if (response.data.t) {
          this.useWebRTC = response.data.t !== "sip";
        }
        this.viewReady = true;
      }).catch((err) => {
        console.log(err);
        this.$router.push('/login');
      }).finally(() => {
        this.loading = false;
      });
    },
    beforeUnload: function () {
      console.log("before unload");
      // this.sendResponseViewReset();
    },
  }

}
</script>

<style scoped>
</style>
